import { AxiosResponse } from 'axios';
import axiosClient from '../clients/axios-client';
import {
	Company,
	CompanyUpdateDTO,
	CompanyUpdateProjectTypesDTO,
} from '../data/models/company';
import { GoogleUser, User, UsersFilter } from '../data/models/user';
import { stringifyReqObj } from '../utils/common-utils';
import { Template } from '../data/models/template';

export async function fetchCompany(): Promise<Company> {
	const graphqlQuery = `
            {
                company{
                    ${companyROGraphQl}
                }
            }
        `;

	const response: AxiosResponse = await axiosClient({
		url: 'graphql',
		method: `POST`,
		data: {
			query: graphqlQuery,
		},
	});
	return response?.data?.company;
}

export async function updateCompany(
	company: CompanyUpdateDTO
): Promise<Company> {
	const graphqlQuery = `
            mutation {
                updateCompany(
                    data: ${stringifyReqObj(company)},
                ) {
                    ${companyROGraphQl}
                }
            }
        `;

	const response: AxiosResponse = await axiosClient({
		url: 'graphql',
		method: `POST`,
		data: {
			query: graphqlQuery,
		},
	});
	return response?.data?.updateCompany;
}

export async function updateQBInfoSheet(googleId: string): Promise<Company> {
	const graphqlQuery = `
            mutation {
                updateQBInfoSheet(
                    googleId: "${googleId}",
                ) {
                    ${companyROGraphQl}
                }
            }
        `;

	const response: AxiosResponse = await axiosClient({
		url: 'graphql',
		method: `POST`,
		data: {
			query: graphqlQuery,
		},
	});
	return response?.data?.updateQBInfoSheet;
}

export async function fetchGoogleUsers(): Promise<GoogleUser[]> {
	const graphqlQuery = `
            {
                googleUsers{
                    ${googleUsersROGraphQl}
                }
            }
        `;

	const response: AxiosResponse = await axiosClient({
		url: 'graphql',
		method: `POST`,
		data: {
			query: graphqlQuery,
		},
	});
	return response?.data?.googleUsers;
}

export async function updateCompanyProjectTypes(
	data: CompanyUpdateProjectTypesDTO[]
): Promise<Company> {
	const graphqlQuery = `
            mutation {
                updateCompanyProjectTypes(
                    data: ${stringifyReqObj(data)},
                ) {
                    ${companyROGraphQl}
                }
            }
        `;

	const response: AxiosResponse = await axiosClient({
		url: 'graphql',
		method: `POST`,
		data: {
			query: graphqlQuery,
		},
	});
	return response?.data?.updateCompanyProjectTypes;
}

// export async function fetchCompanyTemplates(): Promise<{
// 	list: Template[];
// 	count: number;
// }> {
// 	const graphqlQuery = `
//             {
//                 companyTemplates {
//                     ${companyTemplatesROGraphQl}
//                 }
//             }
//         `;

// 	const response: AxiosResponse = await axiosClient({
// 		url: 'graphql',
// 		method: `POST`,
// 		data: {
// 			query: graphqlQuery,
// 		},
// 	});
// 	return response?.data?.templates;
// }

// const companyTemplatesROGraphQl = `
//     list {
//         id
//         name
//         description
//         created
//         updated
//         mimeType
//         fileSize
//         versions {
//             version
//             googleId
//         }
//         activeVersion {
//             version
//             googleId
//         }
//         active
//     }
//     count
// `;

const companyROGraphQl = `
    id
    name
    quickLinks {
        id
        title
        url
        icon
        order
    }
    projectTypes {
        id
        display
        color
        textColor
        active
        createdBy {
            name
        }
        created
        updated
    }
    subscription {
        connectedBy {
            id
            name
        }
        freeTrialActive
        freeTrialStart
        freeTrialEnd
        subscriptionId
        customerId
        status
        subscriptionStartDate
        subscriptionEndDate
    }
    quickBooksTimeConnection {
        lastFetchedRecordsFromDb
        failure
        lastFailureReason
        lastFailureDate
        qbInfoSheets {
            key
            value {
                googleFileId
                qbProjectName
                lastUpdated
                failure
                failureReason
                sheetName
            }
        }
    }
`;

const googleUsersROGraphQl = `
    name {
        givenName
        familyName
    }
    primaryEmail
    creationTime
    id
`;
