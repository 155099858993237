import React, { useEffect } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../data/store';
import { fetchCompany } from '../../data/thunks/companyThunk';
import { showAlert } from '../../data/stores/alertStore';
import { Box, CircularProgress, Typography } from '@mui/material';

export default function SubscriptionComplete() {
	const stripe = useStripe();
	const navigate = useNavigate();
	const dispatch = useDispatch<AppDispatch>();

	useEffect(() => {
		if (!stripe) {
			return;
		}

		const clientSecret = new URLSearchParams(window.location.search).get(
			'payment_intent_client_secret'
		);

		if (clientSecret) {
			stripe
				.retrievePaymentIntent(clientSecret)
				.then(({ paymentIntent }) => {
					if (paymentIntent?.status === 'succeeded') {
						// Refresh company data to get updated subscription status
						dispatch(fetchCompany());
						dispatch(
							showAlert({
								message: 'Subscription activated successfully!',
								severity: 'success',
							})
						);
						navigate('/company');
					} else {
						dispatch(
							showAlert({
								message:
									'Payment was not completed successfully.',
								severity: 'error',
							})
						);
						navigate('/company');
					}
				});
		}
	}, [stripe, navigate, dispatch]);

	return (
		<Box
			display="flex"
			flexDirection="column"
			alignItems="center"
			justifyContent="center"
			minHeight="50vh"
			gap={2}
		>
			<CircularProgress />
			<Typography variant="h6">
				Processing your subscription...
			</Typography>
		</Box>
	);
}
