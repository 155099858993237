import React, { useState } from 'react';
import TemplatesTable from './TemplatesTable';
import styled from 'styled-components';
import { Box, Divider, Drawer, IconButton } from '@mui/material';
import { getIconFromGoogleMimeType } from '../../utils/company-utils';
import CloseIcon from '@mui/icons-material/Close';
import Label from '../common/Label';
import dayjs from 'dayjs';
import { useTheme } from '@mui/material/styles';
import {
	Template,
	getReadableGoogleMimeType,
} from '../../data/models/template';
import { useLocation } from 'react-router-dom';
import Tabs from '../common/Tabs';
import { TableHeader } from '../common/TableToolbar';
import AddCustomTemplateModal from './AddCustomTemplateModal';
import EditCustomTemplateModal from './EditCustomTemplateModal';
import TemplateMappingTable from './TemplateMappingTable';
import ConfigureTemplateMappingModal from './ConfigureTemplateMappingModal';
import ScreenBusySpinner from '../common/ScreenBusySpinner';
import { ProjectCategories } from '../../data/models/project';

export default function TemplatesScreen() {
	const location = useLocation();
	const theme = useTheme();

	const [drawerOpen, setDrawerOpen] = useState(false);
	const [selectedTemplate, setSelectedTemplate] = useState<Template | null>(
		null
	);

	const handleRowClick = (template) => {
		if (template?.id === selectedTemplate?.id) {
			setSelectedTemplate(null);
			setDrawerOpen(false);
		} else {
			setSelectedTemplate(template);
			setDrawerOpen(true);
		}
	};

	const handleDrawerClose = () => {
		setDrawerOpen(false);
		setSelectedTemplate(null);
	};

	const basePath = '/templates';
	const panels = [
		{
			label: 'Default',
			component: <TemplatesTable handleRowClick={handleRowClick} />,
			href: `${basePath}/default`,
			key: `${basePath}/default`,
		},
		{
			label: 'Custom',
			component: <TemplatesTable handleRowClick={handleRowClick} />,
			href: `${basePath}/custom`,
			key: `${basePath}/custom`,
		},
		{
			label: 'Mapping',
			component: <TemplateMappingTable />,
			href: `${basePath}/mapping`,
			key: `${basePath}/mapping`,
		},
	];

	return (
		<>
			<ScreenBusySpinner />
			<AddCustomTemplateModal />
			<EditCustomTemplateModal />
			<ConfigureTemplateMappingModal />
			<Container>
				<TemplatesTableWrapper drawerOpen={drawerOpen}>
					<Box
						sx={{
							width: '100%',
							backgroundColor: theme.palette.background.paper,
							borderRadius: 2,
							position: 'relative',
							flex: 1,
							overflow: 'auto',
							height: 'calc(100vh - 98px)',
							padding: 1,
						}}
					>
						<TableHeader>Templates</TableHeader>
						<Tabs panels={panels} currentPath={location.pathname} />
					</Box>
				</TemplatesTableWrapper>
				<InfoDrawer
					open={drawerOpen}
					onClose={handleDrawerClose}
					template={selectedTemplate}
				/>
			</Container>
		</>
	);
}

const InfoDrawer = (props) => {
	const { open, onClose, template } = props;

	if (!template) return null;

	const icon = getIconFromGoogleMimeType(template.mimeType);

	const details = [
		{ name: 'Version', value: template.activeVersion.version.toFixed(1) },
		{ name: 'Type', value: getReadableGoogleMimeType(template.mimeType) },
		{
			name: 'Project Category',
			value:
				ProjectCategories[template.projectCategory]?.display ||
				template.projectCategory,
		},
		{ name: 'Size', value: `${template.fileSize} KB` },
		{
			name: 'Created Date',
			value: dayjs(template.created).format('MMM D, YYYY'),
		},
		{
			name: 'Modified',
			value: dayjs(template.updated).format('MMM D, YYYY'),
		},
	];

	return (
		<Drawer
			anchor="right"
			open={open}
			variant="persistent"
			onClose={onClose}
			ModalProps={{
				keepMounted: true,
			}}
			PaperProps={{
				style: { width: 350, border: 'none' },
			}}
		>
			<DrawerContainer>
				<StyledCloseButton onClick={onClose}>
					<CloseIcon />
				</StyledCloseButton>
				<NameIconContainer>
					<img
						style={{
							height: 20,
							marginRight: 10,
							objectFit: 'cover',
							cursor: 'pointer',
						}}
						src={icon.icon}
						alt={icon.alt}
					/>
					<TemplateName>{template.name}</TemplateName>
				</NameIconContainer>
				<Label>Description</Label>
				<DescriptionText>{template.description}</DescriptionText>
				<StyledDivider />
				<FileDetails>File Details</FileDetails>
				<TemplatesDetails details={details} />
			</DrawerContainer>
		</Drawer>
	);
};

const TemplatesDetails = (props) => {
	const { details } = props;

	return (
		<DetailsContainer>
			{details.map((detail) => (
				<DetailItem key={detail.name}>
					<Label>{detail.name}</Label>
					<DetailValue>{detail.value}</DetailValue>
				</DetailItem>
			))}
		</DetailsContainer>
	);
};

const DescriptionText = styled.div`
	color: ${(props) => props.theme.palette.text.secondary};
`;

const TemplateName = styled.div`
	font-size: 16px;
	max-width: 80%;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const FileDetails = styled.div`
	font-size: 16px;
	font-weight: 500;
`;

const StyledDivider = styled(Divider)`
	margin: 16px -16px !important;
`;

const StyledCloseButton = styled(IconButton)`
	position: absolute !important;
	top: 8px;
	right: 8px;
`;

const Container = styled.div`
	display: flex;
	width: 100%;
`;

const NameIconContainer = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 16px;
`;

const TemplatesTableWrapper = styled.div<{ drawerOpen: boolean }>`
	transition: width 0.3s ease-out;
	width: ${(props) => (props.drawerOpen ? 'calc(100% - 347px)' : '100%')};
`;

const DrawerContainer = styled.div`
	background-color: ${(props) => props.theme.palette.background.paper};
	padding: 16px;
	width: 335px;
	margin-top: 80px;
	border-radius: 8px;
	position: relative;
	font-size: 14px;
`;

const DetailsContainer = styled.div`
	margin-top: 16px;
`;

const DetailItem = styled.div`
	margin-bottom: 8px;
`;

const DetailValue = styled.div`
	margin-left: 8px;
	color: ${(props) => props.theme.palette.text.secondary};
`;
