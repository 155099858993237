import axiosClient from '../clients/axios-client';
import { AxiosResponse } from 'axios';
import { loadStripe } from '@stripe/stripe-js';
import { Stripe } from '@stripe/stripe-js';

let stripePromise: Promise<Stripe | null>;
const getStripe = () => {
	if (!stripePromise) {
		stripePromise = loadStripe(
			// process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!
			'pk_test_51QXQnnFQfPoy2YINLzYULbF7iVCsOU8JHZaNZAYo9mkBkITlSp5kcWleIN7iHtKUu9tXTPccoVzPXUqHtVttXkIe00tqCGCFTy'
		);
	}
	return stripePromise;
};

export interface SubscriptionResponse {
	clientSecret?: string;
	message?: string;
}

export async function createSubscription(): Promise<SubscriptionResponse> {
	const response: any = await axiosClient({
		url: 'api/create-checkout-session',
		method: 'POST',
	});

	return response;
}

export async function getSubscriptionStatus(
	subscriptionId: string
): Promise<{ status: string }> {
	const response: any = await axiosClient({
		url: `api/subscription-status/${subscriptionId}`,
		method: 'GET',
	});
	return response;
}

export async function cancelSubscription(
	subscriptionId: string
): Promise<{ status: string }> {
	const response: any = await axiosClient({
		url: `api/cancel-subscription/${subscriptionId}`,
		method: 'POST',
	});
	return response;
}

export async function reactivateSubscription(
	subscriptionId: string
): Promise<{ status: string }> {
	const response: any = await axiosClient({
		url: `api/reactivate-subscription/${subscriptionId}`,
		method: 'POST',
	});
	return response;
}

export async function createPortalSession(): Promise<{ url: string }> {
	const response: any = await axiosClient({
		url: 'api/create-portal-session',
		method: 'POST',
	});

	return response;
}

export { getStripe };
