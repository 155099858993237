import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../data/store';
import Modal from './common/Modal';
import { styled } from 'styled-components';
import Button from './common/buttons/Button';
import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';
import { Typography } from '@mui/material';

export default function FreeTrialExpiredModal() {
	const { company } = useSelector((state: RootState) => state.companyState);
	const location = useLocation();

	// Check if we're on the company subscription page - we don't want to block that
	const isSubscriptionPage = location.pathname === '/company';

	const isTrialExpired = company.subscription
		? company.subscription.status === 'trial' &&
		  dayjs(company.subscription.freeTrialEnd).isBefore(dayjs())
		: false;

	// Only show if trial is expired and we're not on the subscription page
	const open =
		(company.subscription?.status === 'canceled' || isTrialExpired) &&
		!isSubscriptionPage;

	return (
		<Modal open={open} handleClose={() => {}}>
			<FormContainer>
				{company.subscription?.status === 'canceled' ? (
					<MessageContainer>
						<Typography>
							Your subscription has been canceled.
						</Typography>
					</MessageContainer>
				) : (
					<MessageContainer>
						<h2>Free Trial Expired</h2>
						<p>
							Your free trial period has ended. To continue using
							our services, please upgrade to a paid subscription.
						</p>
						<p>
							Your trial ended on:{' '}
							{dayjs(company.subscription?.freeTrialEnd).format(
								'MMMM D, YYYY'
							)}
						</p>
					</MessageContainer>
				)}
				<Button
					onClick={() => (window.location.href = '/company')}
					variant="contained"
					color="primary"
				>
					Upgrade Now
				</Button>
			</FormContainer>
		</Modal>
	);
}

const MessageContainer = styled.div`
	margin-bottom: 20px;
	text-align: center;

	h2 {
		color: ${({ theme }) => theme.palette.error.main};
		margin-bottom: 16px;
	}

	p {
		margin-bottom: 8px;
	}
`;

const FormContainer = styled.div`
	text-align: center;
	padding: 20px;
	max-width: 400px;
	margin: 0 auto;
`;
