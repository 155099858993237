import { createAsyncThunk, Dispatch } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';
import {
	asyncCallStart,
	asyncCallError,
	asyncCallDone,
	refreshClientSuccess,
} from '../stores/clientStore';
import * as CompanyService from '../../services/company-service';
import * as StripeService from '../../services/stripe-service';
import { SubscriptionResponse } from '../../services/stripe-service';
import { showAlert } from '../stores/alertStore';
import {
	fetchUsersSuccess,
	refreshCompanySuccess,
	updateCompanySuccess,
} from '../stores/companyStore';
import { UsersFilter } from '../models/user';
import { CompanyUpdateDTO } from '../models/company';

interface ThunkState {
	companyState: {
		company: {
			subscription?: {
				id: string;
				status: string;
			};
		};
	};
}

export const fetchCompany = (): AppThunk => async (dispatch: Dispatch) => {
	dispatch(asyncCallStart());
	try {
		const response = await CompanyService.fetchCompany();
		dispatch(refreshCompanySuccess(response));
	} catch (error) {
		dispatch(asyncCallError(error));
		dispatch(
			showAlert({
				message: 'An error occurred while fetching the Company',
				severity: 'error',
			})
		);
	}
	dispatch(asyncCallDone());
};

export const updateCompany =
	(company: CompanyUpdateDTO): AppThunk =>
	async (dispatch: Dispatch) => {
		dispatch(asyncCallStart());
		try {
			const updateCompany = await CompanyService.updateCompany(company);
			dispatch(updateCompanySuccess(updateCompany));
		} catch (error) {
			dispatch(asyncCallError(error));
			return dispatch(
				showAlert({
					message: 'An error occurred while updating the Company',
					severity: 'error',
				})
			);
		}
		dispatch(asyncCallDone());
		dispatch(
			showAlert({
				message: 'Successfully updated the company.',
				severity: 'success',
			})
		);
	};

export const updateQBInfoSheet =
	(googleId: string): AppThunk =>
	async (dispatch: Dispatch) => {
		dispatch(asyncCallStart());
		try {
			const updateCompany = await CompanyService.updateQBInfoSheet(
				googleId
			);
			dispatch(updateCompanySuccess(updateCompany));
		} catch (error) {
			dispatch(asyncCallError(error));
			return dispatch(
				showAlert({
					message:
						'An error occurred while updating the Qb Info Sheet',
					severity: 'error',
				})
			);
		}
		dispatch(asyncCallDone());
		dispatch(
			showAlert({
				message: 'Successfully updated the Qb Info Sheet.',
				severity: 'success',
			})
		);
	};

// export const manageSubscription = createAsyncThunk<
// 	SubscriptionResponse | undefined,
// 	void,
// 	{ state: RootState }
// >('company/manageSubscription', async (_, { getState, dispatch }) => {
// 	const state = getState();
// 	const { company } = state.companyState;

// 	try {
// 		const response = await StripeService.manageSubscription(company);
// 		console.log('response', response);

// 		if (response.message) {
// 			dispatch(
// 				showAlert({
// 					message: response.message,
// 					severity: 'success',
// 				})
// 			);
// 		}

// 		if (response.shouldRefreshCompany) {
// 			const updatedCompany = await CompanyService.fetchCompany();
// 			dispatch(refreshCompanySuccess(updatedCompany));
// 		}

// 		return response.clientSecret
// 			? { clientSecret: response.clientSecret }
// 			: undefined;
// 	} catch (error) {
// 		dispatch(
// 			showAlert({
// 				message: 'An error occurred while managing the subscription',
// 				severity: 'error',
// 			})
// 		);
// 		throw error;
// 	}
// });
